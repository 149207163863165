import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const ApplyHFinance = () => {
  return (
    // <div>
    //   <Navbar />
    //   <div class="max-w-[50rem] flex flex-col mx-auto size-full">
    //     <header class="mb-auto flex justify-center z-50 w-full py-4">
    //       <nav class="px-4 sm:px-6 lg:px-8">
    //         <a
    //           class="flex-none text-xl font-semibold sm:text-3xl"
    //           href="#"
    //           aria-label="Brand"
    //         >
    //           <img
    //             src="https://res.cloudinary.com/djqf7eivl/image/upload/v1720532327/ee37d213-8ff5-41f4-aef3-06396686117e_s7iuyv.png"
    //             alt="Brand Logo"
    //             class="w-48 h-36"
    //           />
    //         </a>
    //       </nav>
    //     </header>

    //     <main id="content">
    //       <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
    //         <h1 class="block text-7xl font-bold text-gray-800 sm:text-9xl"></h1>
    //         <p class="mt-3 text-gray-600">
    //           For inquiries about Home Finance options, please reach out to our
    //           Insaaf team. They’ll be happy to provide you with personalized
    //           assistance and answer any questions you may have. You can contact
    //           them directly at{" "}
    //           <span className="font-bold">info@insaaf.com.au</span> or call{" "}
    //           <span className="font-bold">1300 008 786.</span>
    //         </p>
    //         <p class="text-gray-600">
    //           Thank you for considering our services; we look forward to helping
    //           you with your home finance needs.
    //         </p>
    //         <div class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
    //           {/* <a
    //             class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
    //             href="../examples.html"
    //           >
    //             <svg
    //               class="shrink-0 size-4"
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="24"
    //               height="24"
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               stroke="currentColor"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             >
    //               <path d="m15 18-6-6 6-6" />
    //             </svg>
    //             Back to Home
    //           </a> */}
    //         </div>
    //       </div>
    //     </main>

    //     <footer class="mt-auto text-center py-5">
    //       <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
    //         <p class="text-sm text-gray-500">
    //           © 2024 Insaaf. Developed by XYTR.
    //         </p>
    //       </div>
    //     </footer>
    //   </div>
    //   <Footer />
    // </div>

    // form code

    <div className="min-h-screen bg-gradient-to-b from-white to-white">
      <Navbar />
      <div className="flex justify-center items-center pt-16">
        <div className="mb-24 w-full max-w-6xl p-2 bg-white shadow-2xl rounded-lg">
          <iframe
            width="100%"
            height="900px"
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=q3lUF5HM_0aq74EOeri3CfDh4W_CD9xPpagjVlgxDE1UM0lPQ0pRNFpYMUVQTFlSTzUyRjVNVk05MC4u&embed=true"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            className="rounded-md"
            style={{ border: "none" }}
            allowFullScreen
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            msallowfullscreen="true"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ApplyHFinance;
