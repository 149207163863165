// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axiosConfig from "../../axiosConfig";

// const Register = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { email } = location.state || {}; // Retrieve email from location state
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [error, setError] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Check if passwords match
//     if (password !== confirmPassword) {
//       setError("Passwords do not match!");
//       return;
//     }

//     try {
//       // Send signup data to your API
//       const res = await axiosConfig.post("/auth/register", {
//         username: email,
//         contactNumber: phoneNumber,
//         password: password,
//       });

//       // Navigate to success page or perform any action on successful registration
//       if (res.status === 200) {
//         navigate("/success"); // Replace with your success page
//       }
//     } catch (err) {
//       setError(err.response?.data || "Error during registration");
//     }
//   };

//   return (
//     <div>
//       <div className="mx-auto flex h-screen max-w-lg flex-col md:max-w-none md:flex-row md:pr-10">
//         <div className="max-w-md rounded-3xl bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-10 text-white sm:px-10 md:m-6 md:mr-8">
//           <p className="mb-20 font-bold tracking-wider">INSAAF</p>
//           <p className="mb-4 text-3xl font-bold md:text-4xl md:leading-snug">
//             Start your <br />
//             journey with us
//           </p>
//           <p className="mb-28 leading-relaxed text-gray-200">
//             Fill up this sign up form with password of your choice to
//             successfully login into your account.
//           </p>
//         </div>
//         <div className="px-4 py-20">
//           <h2 className="mb-2 text-3xl font-bold">Sign Up</h2>
//           <p className="mb-1 font-medium text-gray-500">Email</p>
//           <div className="mb-4 flex flex-col">
//             <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
//               <input
//                 type="email"
//                 id="signup-email"
//                 value={email || ""}
//                 readOnly
//                 className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
//                 placeholder="Enter your email"
//               />
//             </div>
//           </div>

//           <p className="mb-1 font-medium text-gray-500">Phone Number</p>
//           <div className="mb-4 flex flex-col">
//             <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
//               <input
//                 type="tel"
//                 id="signup-phone"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
//                 placeholder="Enter your phone number"
//               />
//             </div>
//           </div>

//           <p className="mb-1 font-medium text-gray-500">Password</p>
//           <div className="mb-4 flex flex-col">
//             <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
//               <input
//                 type="password"
//                 id="signup-password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
//                 placeholder="Choose a password (minimum 8 characters)"
//               />
//             </div>
//           </div>

//           <p className="mb-1 font-medium text-gray-500">Confirm Password</p>
//           <div className="mb-4 flex flex-col">
//             <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
//               <input
//                 type="password"
//                 id="signup-confirm-password"
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//                 className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
//                 placeholder="Confirm your password"
//               />
//             </div>
//           </div>

//           {error && <p className="text-red-500">{error}</p>}

//           <button
//             onClick={handleSubmit}
//             className="hover:shadow-blue-600/40 rounded-xl bg-gradient-to-r from-blue-700 to-blue-600 px-8 py-3 font-bold text-white transition-all hover:opacity-90 hover:shadow-lg"
//           >
//             Sign Up
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Register;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosConfig from "../../axiosConfig";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {}; // Retrieve email from location state
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    // Validate password criteria
    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long, contain at least one number and one special character."
      );
      return;
    }

    try {
      // Send signup data to your API
      const res = await axiosConfig.post("/auth/register", {
        username: email,
        contactNumber: phoneNumber,
        password: password,
      });

      // Navigate to success page or perform any action on successful registration
      if (res.status === 200) {
        navigate("/success"); // Replace with your success page
      }
    } catch (err) {
      setError(err.response?.data || "Error during registration");
    }
  };

  return (
    <div>
      <div className="mx-auto flex h-screen max-w-lg flex-col md:max-w-none md:flex-row md:pr-10">
        <div className="max-w-md rounded-3xl bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-10 text-white sm:px-10 md:m-6 md:mr-8">
          <p className="mb-20 font-bold tracking-wider">INSAAF</p>
          <p className="mb-4 text-3xl font-bold md:text-4xl md:leading-snug">
            Start your <br />
            journey with us
          </p>
          <p className="mb-28 leading-relaxed text-gray-200">
            Fill up this sign up form with password of your choice to
            successfully login into your account.
          </p>
          <p className="mb-2 leading-relaxed text-gray-200">
            Password must be atleast 8 characters long.
          </p>
          <p className="mb-2 leading-relaxed text-gray-200">
            Password must contain atleast one number.
          </p>
          <p className="mb-2 leading-relaxed text-gray-200">
            Password also must contain one special character.
          </p>
        </div>
        <div className="px-4 py-20">
          <h2 className="mb-2 text-3xl font-bold">Sign Up</h2>
          <p className="mb-1 font-medium text-gray-500">Email</p>
          <div className="mb-4 flex flex-col">
            <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
              <input
                type="email"
                id="signup-email"
                value={email || ""}
                readOnly
                className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                placeholder="Enter your email"
              />
            </div>
          </div>

          <p className="mb-1 font-medium text-gray-500">Phone Number</p>
          <div className="mb-4 flex flex-col">
            <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
              <input
                type="tel"
                id="signup-phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                placeholder="Enter your phone number"
              />
            </div>
          </div>

          <p className="mb-1 font-medium text-gray-500">Password</p>
          <div className="mb-4 flex flex-col">
            <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
              <input
                type="password"
                id="signup-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                placeholder="Choose a password"
              />
            </div>
          </div>

          <p className="mb-1 font-medium text-gray-500">Confirm Password</p>
          <div className="mb-4 flex flex-col">
            <div className="relative flex overflow-hidden rounded-md border-2 transition sm:w-80 lg:w-full">
              <input
                type="password"
                id="signup-confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full border-gray-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                placeholder="Confirm your password"
              />
            </div>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          <button
            onClick={handleSubmit}
            className="hover:shadow-blue-600/40 rounded-xl bg-gradient-to-r from-blue-700 to-blue-600 px-8 py-3 font-bold text-white transition-all hover:opacity-90 hover:shadow-lg"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
