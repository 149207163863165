import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <footer class="mt-auto bg-gray-900 w-full rounded-xl">
        <div class="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
          <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
            <div class="col-span-full lg:col-span-1">
              <a
                class="flex-none text-xl font-semibold text-white focus:outline-none focus:opacity-80"
                href="#"
                aria-label="Brand"
              >
                Insaaf
              </a>
            </div>

            <div class="col-span-1">
              <h4 class="font-semibold text-gray-100">Apply for Finance</h4>

              <div class="mt-3 grid space-y-3">
                <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="#"
                    onClick={() => {
                      navigate("/vehicle-loan-application");
                    }}
                  >
                    Vehicle Finance
                  </a>
                </p>
                <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="#"
                    onClick={() => {
                      navigate("/home-loan-application");
                    }}
                  >
                    Home Finance
                  </a>
                </p>
                <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="#"
                    onClick={() => {
                      navigate("/business-loan-application");
                    }}
                  >
                    Business Finance
                  </a>
                </p>
              </div>
            </div>

            <div class="col-span-1">
              <h4 class="font-semibold text-gray-100">Our Products</h4>

              <div class="mt-3 grid space-y-3">
                <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="https://www.insaaf.com.au/home-finance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home Finance
                  </a>
                </p>
                <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="https://www.insaaf.com.au/vehicle-finance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vehicle Finance
                  </a>
                </p>
                {/* <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="#"
                  >
                    Careers
                  </a>{" "}
                  <span class="inline-block ms-1 text-xs bg-blue-700 text-white py-1 px-2 rounded-lg">
                    We're hiring
                  </span>
                </p> */}
                <p>
                  <a
                    class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200"
                    href="https://www.insaaf.com.au/service/business-asset-finance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Business Finance
                  </a>
                </p>
              </div>
            </div>

            <div class="col-span-2">
              <h4 class="font-semibold text-gray-100">Stay up to date</h4>

              <form>
                <div class="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-lg p-2">
                  <div class="w-full">
                    <label for="hero-input" class="sr-only">
                      Subscribe
                    </label>
                    <input
                      type="text"
                      id="hero-input"
                      name="hero-input"
                      class="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder="Enter your email"
                    />
                  </div>
                  <a
                    class="w-full sm:w-auto whitespace-nowrap p-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    href="#"
                  >
                    Subscribe
                  </a>
                </div>
                <p class="mt-3 text-sm text-gray-400">
                  Get up to date about our product. Never spam.
                </p>
              </form>
            </div>
          </div>

          <div class="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
            <div class="flex justify-between items-center">
              <a
                class="text-sm text-gray-400"
                href="https://xytr.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                © 2024 Insaaf. Developed by XYTR.
              </a>
            </div>

            <div>
              <a
                class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 focus:outline-none focus:bg-white/10 disabled:opacity-50 disabled:pointer-events-none"
                href="https://www.facebook.com/p/Insaaf-Australia-100063967668626/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </a>
              <a
                class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 focus:outline-none focus:bg-white/10 disabled:opacity-50 disabled:pointer-events-none"
                href="https://www.google.com/search?q=insaaf+australia&sca_esv=15ad087ec5b46880&sxsrf=ADLYWILn47B8Cj-NTyO2DC67wXPSQLofIQ%3A1723797600210&source=hp&ei=YBC_Zq7aCs6Svr0PwfKhuAI&iflsig=AL9hbdgAAAAAZr8ecL-7-IQSeRo9tCvy4qNl4Z0hMYU1&oq=insaaf+austr&gs_lp=Egdnd3Mtd2l6IgxpbnNhYWYgYXVzdHIqAggAMgUQABiABDILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEiwN1AAWLYrcAB4AJABAJgB7QGgAesRqgEFMC45LjO4AQPIAQD4AQGYAgygAskSwgIKECMYgAQYJxiKBcICERAuGIAEGJECGNEDGMcBGIoFwgILEAAYgAQYkQIYigXCAgsQABiABBixAxiDAcICDhAAGIAEGLEDGIMBGIoFwgIFEC4YgATCAhEQLhiABBixAxjRAxiDARjHAcICChAAGIAEGEMYigXCAg0QLhiABBixAxhDGIoFwgIKEC4YgAQYQxiKBcICEBAAGIAEGLEDGEMYgwEYigXCAg8QABiABBhDGIoFGEYY-QHCAg0QLhiABBhDGNQCGIoFwgINEAAYgAQYsQMYgwEYCsICCxAuGIAEGLEDGIMBwgILEC4YgAQYsQMY1ALCAgcQLhiABBgKwgIQEAAYgAQYsQMYgwEYRhj_AcICCBAuGIAEGNQCwgIHEAAYgAQYCsICCBAAGBYYChgewgIIEAAYFhgeGA_CAgYQABgWGB6YAwCSBwUwLjcuNaAHxnw&sclient=gws-wiz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                </svg>
              </a>
              <a
                class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 focus:outline-none focus:bg-white/10 disabled:opacity-50 disabled:pointer-events-none"
                href="https://www.linkedin.com/company/insaaf-australia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="shrink-0 size-4"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
              <a
                class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 focus:outline-none focus:bg-white/10 disabled:opacity-50 disabled:pointer-events-none"
                href="mailto:info@insaaf.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="shrink-0 size-4"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                </svg>
              </a>
              <a
                class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 focus:outline-none focus:bg-white/10 disabled:opacity-50 disabled:pointer-events-none"
                href="https://www.insaaf.com.au/contact-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="shrink-0 size-4"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
