import React, { useState } from "react";
import axiosConfig from "../../axiosConfig";
import { useNavigate } from "react-router-dom";

const EmailVerify = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosConfig.post("/verify/email", { email });
      if (res.status === 200) {
        navigate("/sign-up", { state: { email } });
      }
    } catch (err) {
      if (err.response?.status === 404) {
        alert(
          "Complete the Membership Application Form, or if done, provide your registered Email ID received from INSAAF"
        );
      } else {
        alert("An error occurred while verifying the email.");
      }
    }
  };

  return (
    // <form onSubmit={handleSubmit}>
    //   <input
    //     type="email"
    //     value={email}
    //     onChange={(e) => setEmail(e.target.value)}
    //     placeholder="Enter your email"
    //   />
    //   <button type="submit">Verify Email</button>
    //   {error && <p>{error}</p>}
    // </form>

    <div class="antialiased bg-white mt-4">
      <div class="flex justify-center pt-12 md:justify-start md:pl-12">
        <a
          href="https://www.insaaf.com.au/"
          class="text-2xl font-bold text-blue-600"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* {" "}
              Wobble .{" "} */}
          <img src="https://res.cloudinary.com/djqf7eivl/image/upload/v1720533397/m7wlzvok_wi4fct.png" />
        </a>
      </div>
      <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
        <h1 class="text-4xl font-medium">Verify your Email</h1>
        <p class="text-slate-500">Fill up the form to set your password</p>

        <form action="" class="my-10" onSubmit={handleSubmit}>
          <div class="flex flex-col space-y-5">
            <label for="email">
              <p class="font-medium text-slate-700 pb-2">Email address</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder="Enter email address"
              />
            </label>

            <button
              type="submit"
              class="w-full py-3 font-medium text-white bg-[#1d4ed8] hover:bg-indigo-700 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                />
              </svg>

              <span>Set Password</span>
            </button>
            <p class="text-center">
              Already done with the process?{" "}
              <a
                href="/login"
                class="text-indigo-600 font-medium inline-flex space-x-1 items-center"
              >
                <span>Login now! </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </span>
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailVerify;
