// src/App.jsx
import React from "react";
import ProfileCard from "../../components/ProfileCard";

// const memberData = {
//   member_number: "123456",
//   member_name: "John Doe",
//   email: "john.doe@example.com",
//   mobile_number: "123-456-7890",
//   address: "123 Main St",
//   postcode: "12345",
//   state: "CA",
//   license: "AB1234567",
//   general_member_status: "Active",
//   primary_email: "john.primary@example.com",
// };

const Profile = ({ memberUID }) => {
  return (
    <div>
      <ProfileCard memberUID={memberUID} />
    </div>
  );
};

export default Profile;
