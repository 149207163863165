import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Redirect to login page immediately after logout
  //   navigate("/login", { replace: true });
  // }, [navigate]);

  // useEffect(() => {
  //   // Redirect to login if the user tries to navigate back
  //   const handlePopState = (event) => {
  //     navigate("/login", { replace: true }); // Navigate to login and replace history
  //   };

  //   // Listen for popstate event when the user tries to navigate back
  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", handlePopState);

  //   // Cleanup on component unmount
  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  return (
    <div>
      <div class="max-w-[50rem] flex flex-col mx-auto size-full">
        <header class="mb-auto flex justify-center z-50 w-full py-4">
          <nav class="px-4 sm:px-6 lg:px-8">
            <a
              class="flex-none text-xl font-semibold sm:text-3xl"
              href="#"
              aria-label="Brand"
            >
              <img src="https://res.cloudinary.com/djqf7eivl/image/upload/v1720532327/ee37d213-8ff5-41f4-aef3-06396686117e_s7iuyv.png" />
            </a>
          </nav>
        </header>

        <main id="content">
          <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
            <h1 class="block text-7xl font-bold text-gray-800 sm:text-9xl">
              👋
            </h1>
            <p class="mt-3 text-gray-600">Successfully, Logged out.</p>
            <p class="text-gray-600">See you soon</p>
            <div class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
              <a
                class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                href="/login"
              >
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m15 18-6-6 6-6" />
                </svg>
                Back to Login Page
              </a>
            </div>
          </div>
        </main>

        <footer class="mt-auto text-center py-5">
          <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <p class="text-sm text-gray-500">© All Rights Reserved. 2022.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Logout;
