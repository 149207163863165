import React, { useEffect, useState } from "react";
import axiosConfig from "../axiosConfig";
import { useNavigate } from "react-router-dom";

const SelectMemberUID = ({ setMemberUID }) => {
  const [memberUIDs, setMemberUIDs] = useState([]);
  const [selectedUID, setSelectedUID] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMemberUIDs = async () => {
      try {
        const response = await axiosConfig.get("/member/uid");
        setMemberUIDs(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchMemberUIDs();
  }, []);

  const handleSelect = (event) => {
    const uid = event.target.value;
    setSelectedUID(uid);
    setMemberUID(uid);
    navigate("/dashboard");
  };

  return (
    <div>
      <select
        class="py-3 px-4 pe-9 block w-full border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        value={selectedUID}
        onChange={handleSelect}
      >
        <option selected="">Open this select menu</option>
        {memberUIDs.map((uid) => (
          <option key={uid.memberUID} value={uid.memberUID}>
            {uid.memberUID}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectMemberUID;
