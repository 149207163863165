import axiosConfig from "../axiosConfig";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const HomeLoanCard = ({ memberUID }) => {
  // const dummyData = [
  //   { MemberID: "001", Date: "2023-07-01", Amount: "1000", LoanType: "Home" },
  //   { MemberID: "002", Date: "2023-07-05", Amount: "5000", LoanType: "Car" },
  //   {
  //     MemberID: "003",
  //     Date: "2023-07-10",
  //     Amount: "3000",
  //     LoanType: "Business",
  //   },
  //   { MemberID: "004", Date: "2023-07-15", Amount: "7000", LoanType: "Home" },
  // ];
  const navigate = useNavigate();
  const [homeloanDetails, setHomeloanDetails] = useState([]);
  const [homeLoanRepayDetails, setHomeLoanRepayDetails] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    const fetchHomeloanDetails = async () => {
      try {
        const response = await axiosConfig.post("/home/loan/details", {
          memberUID,
        });
        setHomeloanDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchHomeLoanRepayDetails = async () => {
      try {
        const response = await axiosConfig.post(
          "/home/finance/repayment/details",
          {
            memberUID,
          }
        );
        setHomeLoanRepayDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchHomeloanDetails();
    fetchHomeLoanRepayDetails();
  }, [memberUID]);

  const filteredData = homeloanDetails.filter((data) => {
    const searchTerm = searchFilter.toLowerCase();
    return (
      data.FinanceID.toLowerCase().includes(searchTerm) ||
      data.FinanceNumber.toLowerCase().includes(searchTerm) ||
      data.Date.toLowerCase().includes(searchTerm) ||
      data.Amount.toString().toLowerCase().includes(searchTerm)
    );
  });

  const exportToXLS = () => {
    if (homeloanDetails.length === 0) {
      console.warn("No data to export");
      return;
    }
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      homeloanDetails.map((data) => ({
        "Member ID": data.MemberID,
        "Finance ID": data.FinanceID,
        Date: data.Date,
        Amount: data.Amount,
      }))
    );

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Home Finance Repayments");

    const MemberID = homeloanDetails[0].MemberID;
    const filename = `${MemberID}_Home_Finance_Repayments.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  const exportRepayToXLS = () => {
    if (homeLoanRepayDetails.length === 0) {
      console.warn("No data to export");
      return;
    }

    const wb = XLSX.utils.book_new();

    const repayLoanSheet = XLSX.utils.json_to_sheet(
      homeLoanRepayDetails.map((data) => ({
        "Member ID": data.MemberID,
        "Finance ID": data.FinanceID,
        "Start Date": data.StartDate,
        "End Date": data.EndDate,
        "Loan Status": data.LoanStatus,
        "No. of Payments": data.NoOfPayment,
        "Scheduled Payment Amount": data.ScheduledPaymentAmount,
        "Purchasing Price": data.PurchasingPrice,
        "Insaaf Contribution": data.InsaafContribution,
        "Member Advance Payment": data.MemberAdvancePayment,
      }))
    );

    XLSX.utils.book_append_sheet(wb, repayLoanSheet, "Home Finance Summary");

    // Use the first FinanceID in the filename
    const MemberID = homeLoanRepayDetails[0].MemberID;
    const filename = `${MemberID}_Home_Finance_Summary.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  return (
    <div>
      <div
        style={{
          // position: "relative",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/djqf7eivl/image/upload/v1723668041/copropinv4_yx4ich.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0.75rem",
          overflow: "hidden",
        }}
      >
        <div class="max-w-5xl mx-auto px-4 xl:px-0 pt-24 lg:pt-16 pb-12">
          <h1 class="font-semibold text-white text-5xl md:text-3xl">
            <span class="text-blue-400">Insaaf Home Finance:</span> Financial
            Solutions That Save People From Riba
          </h1>
          <div class="max-w-4xl">
            <p class="mt-4 text-neutral-200 text-base font-normal">
              Our shariah-compliant financing solutions are here to help you to
              meet your property, vehicle or commercial need. Our mission is to
              see our community living a peaceful shariah-compliant financial
              life within the next 10 years.
            </p>
            <a
              class="mt-4 py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              href="#"
              onClick={() => {
                navigate("/home-loan-application");
              }}
            >
              Apply for Home Finance
            </a>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-8">
        <div className="flex justify-between">
          <a class="py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg shadow-md border-gray-200 border-[1px] bg-white text-black hover:bg-gray-300 focus:outline-none focus:bg-white disabled:opacity-50 disabled:pointer-events-none">
            Ongoing Home Finance Summary
          </a>
          <button
            class="py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={exportRepayToXLS}
          >
            Export
          </button>
        </div>
        <div className="mt-4 overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
              <tr>
                <th className="py-3 px-6 text-left">Finance ID</th>
                <th className="py-3 px-6 text-left">Contract No.</th>
                <th className="py-3 px-6 text-left">Start Date</th>
                <th className="py-3 px-6 text-left">End Date</th>
                <th className="py-3 px-6 text-center">Payments Terms</th>
                <th className="py-3 px-6 text-center">Scheduled Payment($)</th>
                <th className="py-3 px-6 text-center">Purchase Price($)</th>
                <th className="py-3 px-6 text-center">
                  Insaaf Contribution($)
                </th>
                <th className="py-3 px-6 text-right">Advance Payment($)</th>
                {/* <th className="py-3 px-6 text-left">Loan Type</th> */}
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {homeLoanRepayDetails.map((data, index) => (
                <tr
                  key={index}
                  className={`border-b border-gray-200 hover:bg-gray-100 ${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.FinanceID}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.FinanceNumber}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.StartDate}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.EndDate}
                  </td>
                  <td className="py-3 px-6 text-center">{data.NoOfPayment}</td>
                  <td className="py-3 px-6 text-center">
                    {data.ScheduledPaymentAmount}
                  </td>
                  <td className="py-3 px-6 text-center">
                    {data.PurchasingPrice}
                  </td>
                  <td className="py-3 px-6 text-center">
                    {data.InsaafContribution}
                  </td>
                  <td className="py-3 px-6 text-right">
                    {data.MemberAdvancePayment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="flex justify-end">
          <button
            class="mt-4 py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={exportToXLS}
          >
            Export
          </button>
        </div> */}
      </div>

      <div className="container mx-auto py-8">
        <div className="flex justify-between">
          <a class="py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg shadow-md border-gray-200 border-[1px] bg-white text-black hover:bg-gray-300 focus:outline-none focus:bg-white disabled:opacity-50 disabled:pointer-events-none">
            Home Finance Repayment Summary
          </a>
          <div className="flex space-x-4">
            <input
              type="text"
              className="border px-4 py-3 text-sm w-[500px] rounded-lg border-gray-300 shadow-md"
              placeholder="Search by Finance ID, Contract Number, Date, or Amount"
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
            <button
              class="py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={exportToXLS}
            >
              Export
            </button>
          </div>
        </div>
        <div className="mt-4 overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
              <tr>
                <th className="py-3 px-6 text-left">Member ID</th>
                <th className="py-3 px-6 text-left">Finance ID</th>
                <th className="py-3 px-6 text-left">Contract No.</th>
                <th className="py-3 px-6 text-left">Date</th>
                <th className="py-3 px-6 text-right">Amount($)</th>
                {/* <th className="py-3 px-6 text-left">Loan Type</th> */}
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {homeloanDetails.map((data, index) => (
                <tr
                  key={index}
                  className={`border-b border-gray-200 hover:bg-gray-100 ${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.MemberID}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.FinanceID}
                  </td>
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.FinanceNumber}
                  </td>
                  <td className="py-3 px-6 text-left">{data.Date}</td>
                  <td className="py-3 px-6 text-right">{data.Amount}</td>
                  {/* <td className="py-3 px-6 text-left">{data.FlagSubType}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="flex justify-end">
          <button
            class="mt-4 py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={exportToXLS}
          >
            Export
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default HomeLoanCard;
