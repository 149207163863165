import axiosConfig from "../axiosConfig";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

const InvestmentCard = ({ memberUID }) => {
  // const dummyData = [
  //   { MemberID: "001", Date: "2023-07-01", Amount: "1000", LoanType: "Home" },
  //   { MemberID: "002", Date: "2023-07-05", Amount: "5000", LoanType: "Car" },
  //   {
  //     MemberID: "003",
  //     Date: "2023-07-10",
  //     Amount: "3000",
  //     LoanType: "Business",
  //   },
  //   { MemberID: "004", Date: "2023-07-15", Amount: "7000", LoanType: "Home" },
  // ];

  const [investmentDetails, setInvestmentDetails] = useState([]);

  useEffect(() => {
    const fetchInvestmentDetails = async () => {
      try {
        const response = await axiosConfig.post("/inv/details", { memberUID });
        setInvestmentDetails(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchInvestmentDetails();
  }, [memberUID]);

  const exportToXLS = () => {
    if (investmentDetails.length === 0) {
      console.warn("No data to export");
      return;
    }
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      investmentDetails.map((data) => ({
        "Member ID": data.MemberID,
        Date: data.Date,
        Amount: data.Amount,
      }))
    );

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Investment Details");

    // Export the file
    const MemberID = investmentDetails[0].MemberID;
    const filename = `${MemberID}_Investment_Details.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  return (
    <div>
      <div
        style={{
          // position: "relative",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/djqf7eivl/image/upload/v1723668041/inflation-investment-returnsjpg_s7izzz.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0.75rem",
          overflow: "hidden",
        }}
      >
        <div class="max-w-5xl mx-auto px-4 xl:px-0 pt-24 lg:pt-16 pb-16">
          <h1 class="font-semibold text-white text-5xl md:text-3xl">
            <span class="text-blue-400">Insaaf:</span> Financial Solutions That
            Save People From Riba
          </h1>
          <div class="max-w-4xl">
            <p class="mt-4 text-neutral-200 text-base font-normal">
              Our shariah-compliant financing solutions are here to help you to
              meet your property, vehicle or commercial need. Our mission is to
              see our community living a peaceful shariah-compliant financial
              life within the next 10 years.
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto py-8">
        <div className="flex justify-end">
          <button
            class="py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={exportToXLS}
          >
            Export
          </button>
        </div>
        <div className="mt-4 overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
              <tr>
                <th className="py-3 px-6 text-left">Member ID</th>
                <th className="py-3 px-6 text-left">Date</th>
                <th className="py-3 px-6 text-right">Amount</th>
                {/* <th className="py-3 px-6 text-left">Loan Type</th> */}
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {investmentDetails.map((data, index) => (
                <tr
                  key={index}
                  className={`border-b border-gray-200 hover:bg-gray-100 ${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {data.MemberID}
                  </td>
                  <td className="py-3 px-6 text-left">{data.DateFormatted}</td>
                  <td className="py-3 px-6 text-right">{data.Amount}</td>
                  {/* <td className="py-3 px-6 text-left">{data.LoanType}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end">
          {/* <button
            class="mt-4 py-3 px-4 inline-flex items-center font-medium text-sm rounded-lg bg-blue-700 text-white hover:bg-blue-900 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={exportToXLS}
          >
            Export
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default InvestmentCard;
