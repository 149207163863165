// src/components/ProfileCard.jsx
// import React, { useEffect, useState } from "react";
// import axiosConfig from "../axiosConfig";

// const ProfileCard = ({ memberUID }) => {
//   const [memberDetails, setMemberDetails] = useState([]);

//   useEffect(() => {
//     const fetchMemberDetails = async () => {
//       try {
//         const response = await axiosConfig.post("/member/details", {
//           memberUID,
//         });
//         setMemberDetails(response.data);
//       } catch (err) {
//         console.error(err);
//       }
//     };

//     fetchMemberDetails();
//   }, [memberUID]);

//   return (
//     <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
//       <div className="md:flex">
//         <div className="md:shrink-0">
//           <img
//             className="h-48 w-full object-cover md:h-full md:w-48"
//             src=""
//             alt="Profile"
//           />
//         </div>
//         <div className="p-8">
//           <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//             {memberDetails.Member_Name}
//           </div>
//           <p className="block mt-1 text-lg leading-tight font-medium text-black">
//             {memberDetails.Email}
//           </p>
//           <p className="mt-2 text-gray-500">
//             {memberDetails.Address}, {memberDetails.State},{" "}
//             {memberDetails.Post_Code}
//           </p>
//           <p className="mt-2 text-gray-500">
//             Mobile: {memberDetails.Mobile_Number}
//           </p>
//           <div className="mt-4">
//             <table className="min-w-full leading-normal">
//               <thead>
//                 <tr>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Details
//                   </th>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Value
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Member Number
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Member_Number}
//                   </td>
//                 </tr>
//                 {/* <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     License
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.License}
//                   </td>
//                 </tr> */}
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     General Member Status
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.General_Member_Status}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Primary Email
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Primary_Email}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfileCard;

// import React, { useEffect, useState } from "react";
// import axiosConfig from "../axiosConfig";

// const ProfileCard = ({ memberUID }) => {
//   const [memberDetails, setMemberDetails] = useState({});
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchMemberDetails = async () => {
//       try {
//         console.log("Fetching details for memberUID:", memberUID);

//         const response = await axiosConfig.post("/member/details", {
//           memberUID: memberUID, // Ensure the key matches the backend expectation
//         });

//         console.log("Response data:", response.data);
//         setMemberDetails(response.data);
//       } catch (err) {
//         console.error("Error fetching member details:", err);
//         setError("Failed to fetch member details.");
//       }
//     };

//     if (memberUID) {
//       fetchMemberDetails();
//     } else {
//       setError("No memberUID provided.");
//     }
//   }, [memberUID]);

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
//       <div className="md:flex">
//         <div className="md:shrink-0">
//           <img
//             className="h-48 w-full object-cover md:h-full md:w-48"
//             src=""
//             alt="Profile"
//           />
//         </div>
//         <div className="p-8">
//           <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//             {memberDetails.Member_Name || "Name not available"}
//           </div>
//           <p className="block mt-1 text-lg leading-tight font-medium text-black">
//             {memberDetails.Email || "Email not available"}
//           </p>
//           <p className="mt-2 text-gray-500">
//             {memberDetails.Address}, {memberDetails.State},{" "}
//             {memberDetails.Post_Code}
//           </p>
//           <p className="mt-2 text-gray-500">
//             Mobile: {memberDetails.Mobile_Number}
//           </p>
//           <div className="mt-4">
//             <table className="min-w-full leading-normal">
//               <thead>
//                 <tr>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Details
//                   </th>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Value
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Member Number
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Member_Number}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     General Member Status
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.General_Member_Status}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Primary Email
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Primary_Email}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfileCard;

// import React, { useEffect, useState } from "react";
// import axiosConfig from "../axiosConfig";

// const ProfileCard = ({ memberUID }) => {
//   const [memberDetails, setMemberDetails] = useState(null);

//   useEffect(() => {
//     const fetchMemberDetails = async () => {
//       try {
//         const response = await axiosConfig.post("/member/details", {
//           memberUID,
//         });
//         setMemberDetails(response.data[0]); // Assuming response.data is an array
//       } catch (err) {
//         console.error("Error fetching member details:", err);
//       }
//     };

//     if (memberUID) {
//       fetchMemberDetails();
//     }
//   }, [memberUID]);

//   if (!memberDetails) {
//     return <div>Loading...</div>; // or a skeleton loader
//   }

//   return (
//     <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
//       <div className="md:flex">
//         <div className="md:shrink-0">
//           <img
//             className="h-48 w-full object-cover md:h-full md:w-48"
//             src="https://res.cloudinary.com/djqf7eivl/image/upload/v1723810171/user_4_n8tptd.png"
//             alt="Profile"
//           />
//         </div>
//         <div className="p-8">
//           <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//             {memberDetails.Member_Name}
//           </div>
//           <p className="block mt-1 text-lg leading-tight font-medium text-black">
//             {memberDetails.Email}
//           </p>
//           <p className="mt-2 text-gray-500">
//             {memberDetails.Address}, {memberDetails.State},{" "}
//             {memberDetails.Post_Code}
//           </p>
//           <p className="mt-2 text-gray-500">
//             Mobile: {memberDetails.Mobile_Number}
//           </p>
//           <div className="mt-4">
//             <table className="min-w-full leading-normal">
//               <thead>
//                 <tr>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Details
//                   </th>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Value
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Member Number
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Member_Number}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     General Member Status
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.General_Member_Status}
//                   </td>
//                 </tr>
//                 {/* <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Member Status Active
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Member_Status_Active}
//                   </td>
//                 </tr> */}
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     Primary Email
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     {memberDetails.Primary_Email}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfileCard;

// new code 1

// import React, { useEffect, useState } from "react";
// import axiosConfig from "../axiosConfig";

// const ProfileCard = ({ memberUID }) => {
//   const [memberDetails, setMemberDetails] = useState(null);
//   const [memberType, setMemberType] = useState(""); // Store memberType here

//   useEffect(() => {
//     const fetchMemberDetails = async () => {
//       try {
//         const response = await axiosConfig.post("/member/details", {
//           memberUID,
//         });

//         const details = response.data[0]; // Assuming response.data is an array
//         setMemberDetails(details);

//         // Determine memberType from the returned data
//         if (details.FirstMember_Name && details.SecondMember_MemberName) {
//           setMemberType("Joint Member");
//         } else if (details.EntityName) {
//           setMemberType("Non-Individual Member");
//         } else {
//           setMemberType("Individual Member");
//         }
//       } catch (err) {
//         console.error("Error fetching member details:", err);
//       }
//     };

//     if (memberUID) {
//       fetchMemberDetails();
//     }
//   }, [memberUID]);

//   if (!memberDetails) {
//     return <div>Loading...</div>; // or a skeleton loader
//   }

//   return (
//     <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
//       <div className="md:flex">
//         {memberType === "Individual Member" && (
//           <div className="p-8">
//             <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//               {memberDetails.FirstMember_Name}
//             </div>
//             <p className="block mt-1 text-lg leading-tight font-medium text-black">
//               {memberDetails.FirstMember_Email}
//             </p>
//             <p className="mt-2 text-gray-500">
//               {memberDetails.FirstMember_Address},{" "}
//               {memberDetails.FirstMember_State},
//               {memberDetails.FirstMember_PostCode}
//             </p>
//             <p className="mt-2 text-gray-500">
//               Mobile: {memberDetails.FirstMember_MobileNumber}
//             </p>
//             <p className="mt-2 text-gray-500">
//               General Member Status: {memberDetails.GeneralMember_Status}
//             </p>
//             <p className="mt-2 text-gray-500">
//               Primary Email: {memberDetails.Primary_Email}
//             </p>
//           </div>
//         )}

//         {memberType === "Joint Member" && (
//           <>
//             <div className="p-8">
//               <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//                 {memberDetails.FirstMember_Name}
//               </div>
//               <p className="block mt-1 text-lg leading-tight font-medium text-black">
//                 {memberDetails.FirstMember_Email}
//               </p>
//               <p className="mt-2 text-gray-500">
//                 {memberDetails.FirstMember_Address},{" "}
//                 {memberDetails.FirstMember_State},
//                 {memberDetails.FirstMember_PostCode}
//               </p>
//               <p className="mt-2 text-gray-500">
//                 Mobile: {memberDetails.FirstMember_MobileNumber}
//               </p>
//             </div>
//             <div className="p-8">
//               <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//                 {memberDetails.SecondMember_MemberName}
//               </div>
//               <p className="block mt-1 text-lg leading-tight font-medium text-black">
//                 {memberDetails.SecondMember_Email}
//               </p>
//               <p className="mt-2 text-gray-500">
//                 {memberDetails.SecondMember_Address},{" "}
//                 {memberDetails.SecondMember_State},
//                 {memberDetails.SecondMember_PostCode}
//               </p>
//               <p className="mt-2 text-gray-500">
//                 Mobile: {memberDetails.SecondMember_MobileNumber}
//               </p>
//             </div>
//             <div className="p-8">
//               <p className="mt-2 text-gray-500">
//                 General Member Status: {memberDetails.GeneralMember_Status}
//               </p>
//               <p className="mt-2 text-gray-500">
//                 Primary Email: {memberDetails.Primary_Email}
//               </p>
//             </div>
//           </>
//         )}

//         {memberType === "Non-Individual Member" && (
//           <div className="p-8">
//             <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//               {memberDetails.FirstMember_Name}
//             </div>
//             <p className="block mt-1 text-lg leading-tight font-medium text-black">
//               {memberDetails.FirstMember_Email}
//             </p>
//             <p className="mt-2 text-gray-500">
//               {memberDetails.FirstMember_Address},{" "}
//               {memberDetails.FirstMember_State},
//               {memberDetails.FirstMember_PostCode}
//             </p>
//             <p className="mt-2 text-gray-500">
//               Mobile: {memberDetails.FirstMember_MobileNumber}
//             </p>
//             <p className="mt-2 text-gray-500">
//               Entity Name: {memberDetails.EntityName}
//             </p>
//             <p className="mt-2 text-gray-500">
//               General Member Status: {memberDetails.GeneralMember_Status}
//             </p>
//             <p className="mt-2 text-gray-500">
//               Primary Email: {memberDetails.Primary_Email}
//             </p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ProfileCard;

// new code 2

import React, { useEffect, useState } from "react";
import axiosConfig from "../axiosConfig";

const ProfileCard = ({ memberUID }) => {
  const [memberDetails, setMemberDetails] = useState(null);
  const [memberType, setMemberType] = useState("");

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const response = await axiosConfig.post("/member/details", {
          memberUID,
        });

        const details = response.data[0]; // Assuming response.data is an array
        setMemberDetails(details);

        if (details.FirstMember_Name && details.SecondMember_MemberName) {
          setMemberType("Joint Member");
        } else if (details.EntityName) {
          setMemberType("Non-Individual Member");
        } else {
          setMemberType("Individual Member");
        }
      } catch (err) {
        console.error("Error fetching member details:", err);
      }
    };

    if (memberUID) {
      fetchMemberDetails();
    }
  }, [memberUID]);

  if (!memberDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-md mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden md:max-w-2xl border-2 border-gray-200">
      <div className={memberType !== "Joint Member" ? "md:flex" : ""}>
        {/* Display Profile Picture */}
        {/* <div className="md:flex-shrink-0">
          <img
            className="h-48 w-full object-cover md:h-full md:w-48"
            src={
              memberDetails.profileImage ||
              "https://res.cloudinary.com/djqf7eivl/image/upload/v1723810171/user_4_n8tptd.png"
            }
            alt="Profile"
          />
        </div> */}

        <div className="p-6">
          {memberType === "Individual Member" && (
            <div>
              <p className="font-mono text-lg leading-tight text-black">
                Member ID: {memberDetails.ID}
              </p>
              <div className="mt-4 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                {memberDetails.FirstMember_Name}
              </div>
              <p className="block mt-1 text-lg leading-tight font-medium text-black">
                {memberDetails.FirstMember_Email}
              </p>
              <p className="mt-2 text-gray-500">
                {memberDetails.FirstMember_Address},{" "}
                {memberDetails.FirstMember_State},{" "}
                {memberDetails.FirstMember_PostCode}
              </p>
              <p className="mt-2 text-gray-500">
                Mobile: {memberDetails.FirstMember_MobileNumber}
              </p>
              {/* <p className="mt-2 text-gray-500">
                General Member Status: {memberDetails.GeneralMember_Status}
              </p> */}
              <p className="mt-2 text-gray-500">
                Member Status:{" "}
                {memberDetails.GeneralMember_Status === "Y"
                  ? "General"
                  : "Long-Term (SMSF Member)"}
              </p>
              {/* <p className="mt-2 text-gray-500">
                Member Number: {memberDetails.ID}
              </p> */}
              <p className="mt-2 text-gray-500">
                Primary Email: {memberDetails.Primary_Email}
              </p>
            </div>
          )}

          {memberType === "Joint Member" && (
            <>
              <div className="p-4">
                <p className="font-mono text-lg leading-tight text-black">
                  Member ID: {memberDetails.ID}
                </p>
              </div>
              <div className="p-8 border-2 border-gray-300 rounded-2xl shadow-xl">
                <p className=" text-gray-500 font-medium">Primary User</p>
                <div className="mt-2 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                  {memberDetails.FirstMember_Name}
                </div>
                <p className="block mt-1 text-lg leading-tight font-medium text-black">
                  {memberDetails.FirstMember_Email}
                </p>
                <p className="mt-2 text-gray-500">
                  {memberDetails.FirstMember_Address},{" "}
                  {memberDetails.FirstMember_State},{" "}
                  {memberDetails.FirstMember_PostCode}
                </p>
                <p className="mt-2 text-gray-500">
                  Mobile: {memberDetails.FirstMember_MobileNumber}
                </p>
              </div>

              <div className="mt-6 p-8 border-2 border-gray-300 rounded-2xl shadow-xl">
                <p className=" text-gray-500 font-medium">Secondary User</p>
                <div className="mt-2 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                  {memberDetails.SecondMember_MemberName}
                </div>
                <p className="block mt-1 text-lg leading-tight font-medium text-black">
                  {memberDetails.SecondMember_Email}
                </p>
                <p className="mt-2 text-gray-500">
                  {memberDetails.SecondMember_Address},{" "}
                  {memberDetails.SecondMember_State},{" "}
                  {memberDetails.SecondMember_PostCode}
                </p>
                <p className="mt-2 text-gray-500">
                  Mobile: {memberDetails.SecondMember_MobileNumber}
                </p>
              </div>

              <div className="p-8">
                {/* <p className="mt-2 text-gray-500">
                  General Member Status: {memberDetails.GeneralMember_Status}
                </p> */}
                <p className="mt-2 text-gray-500">
                  Member Status:{" "}
                  {memberDetails.GeneralMember_Status === "Y"
                    ? "General"
                    : "Long-Term (SMSF Member)"}
                </p>
                {/* <p className="mt-2 text-gray-500">
                  Member Number: {memberDetails.ID}
                </p> */}
                <p className="mt-2 text-gray-500">
                  Primary Email: {memberDetails.Primary_Email}
                </p>
              </div>
            </>
          )}

          {memberType === "Non-Individual Member" && (
            <div>
              <p className="font-mono text-lg leading-tight text-black">
                Member ID: {memberDetails.ID}
              </p>
              <div className="mt-4 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                {memberDetails.FirstMember_Name}
              </div>
              <p className="block mt-1 text-lg leading-tight font-medium text-black">
                {memberDetails.FirstMember_Email}
              </p>
              <p className="mt-2 text-gray-500">
                {memberDetails.FirstMember_Address},{" "}
                {memberDetails.FirstMember_State},{" "}
                {memberDetails.FirstMember_PostCode}
              </p>
              <p className="mt-2 text-gray-500">
                Mobile: {memberDetails.FirstMember_MobileNumber}
              </p>
              <p className="mt-2 text-gray-500">
                Entity Name: {memberDetails.EntityName}
              </p>
              {/* <p className="mt-2 text-gray-500">
                General Member Status: {memberDetails.GeneralMember_Status}
              </p> */}
              <p className="mt-2 text-gray-500">
                Member Status:{" "}
                {memberDetails.GeneralMember_Status === "Y"
                  ? "General"
                  : "Long-Term (SMSF Member)"}
              </p>
              {/* <p className="mt-2 text-gray-500">
                Member Number: {memberDetails.ID}
              </p> */}
              <p className="mt-2 text-gray-500">
                Primary Email: {memberDetails.Primary_Email}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
