import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Profile from "./pages/profile/Profile";
import UidSelect from "./pages/uidSelect/UidSelect";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import React, { useState } from "react";
import Investment from "./pages/investment/Investment";
import HomeLoan from "./pages/homeLoan/HomeLoan";
import BusinessLoan from "./pages/businessLoan/BusinessLoan";
import CarLoan from "./pages/carLoan/CarLoan";
import DummyTable from "./components/DummyTable";
import ProfileCard from "./components/ProfileCard";
import Logout from "./pages/logout/Logout";
import EmailVerify from "./pages/emailVerify/EmailVerfiy";
import ApplyVFinance from "./pages/applyVFinance/ApplyVFinance";
import ApplyBFinance from "./pages/applyBFinance/ApplyBFinance";
import ApplyHFinance from "./pages/applyHFinance/ApplyHFinance";

const App = () => {
  const [user, setUser] = useState(null);
  const [memberUID, setMemberUID] = useState(null);

  const router = createBrowserRouter([
    {
      path: "/login",
      element: user ? (
        <Navigate to="/select-uid" replace />
      ) : (
        <Login setUser={setUser} />
      ),
    },
    {
      path: "/email-verify",
      element: <EmailVerify />,
    },
    {
      path: "/sign-up",
      element: <Register />,
    },
    {
      path: "/vehicle-loan-application",
      element: user ? <ApplyVFinance /> : <Navigate to="/login" replace />,
    },
    {
      path: "/business-loan-application",
      element: user ? <ApplyBFinance /> : <Navigate to="/login" replace />,
    },
    {
      path: "/home-loan-application",
      element: user ? <ApplyHFinance /> : <Navigate to="/login" replace />,
    },
    {
      path: "/select-uid",
      element: user ? (
        <UidSelect setMemberUID={setMemberUID} />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/dashboard",
      element: user ? (
        <Dashboard memberUID={memberUID} />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/car-loan",
      element: user ? (
        <CarLoan memberUID={memberUID} />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/investment",
      element: user ? (
        <Investment memberUID={memberUID} />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/home-loan",
      element: user ? (
        <HomeLoan memberUID={memberUID} />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/business-loan",
      element: user ? (
        <BusinessLoan memberUID={memberUID} />
      ) : (
        <Navigate to="/login" replace />
      ),
    },
    {
      path: "/dummy",
      element: user ? <DummyTable /> : <Navigate to="/login" replace />,
    },
    {
      path: "/logout",
      element: user ? <Logout /> : <Navigate to="/login" replace />,
    },
    {
      path: "*", // Catch-all route for undefined routes
      element: <Navigate to="/login" replace />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
