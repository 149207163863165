import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const ApplyVFinance = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-white">
      <Navbar />
      <div className="flex justify-center items-center pt-16">
        <div className="mb-24 w-full max-w-6xl p-2 bg-white shadow-2xl rounded-lg">
          <iframe
            width="100%"
            height="900px"
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=q3lUF5HM_0aq74EOeri3CfDh4W_CD9xPpagjVlgxDE1UM0dDRVlPR0kzMjgzN1VFVUZOSUo0RVNNMS4u&embed=true"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            className="rounded-md"
            style={{ border: "none" }}
            allowFullScreen
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            msallowfullscreen="true"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ApplyVFinance;
